import React, { Fragment } from "react";

import BaseNavbar from "components/Navbars/Exmaple";
import Footer from "components/Footers/DefaultFooter";
// import { useCompany } from "store/company-context";
import Graph from 'react-graph-vis'

import {
  Row,
  // Col,
  // Card,
  // CardBody,
} from 'reactstrap';

import {drawHexagon} from 'helpers/graph'
import { useHistory } from "react-router-dom";
import { BASE_PATH } from "helpers/constants";

// 14 17 18 19
const Security = (props) => {

  const history = useHistory()

  const graph = {
    nodes: [
      { id: 1, label: "Təhlükəsizlik", title: "node 8 tootip text", shape: "custom", size: 90, ctxRenderer: drawHexagon, color: "#1ADE23", },
      { id: 2, label: "Su Mənbələrinin analizi", title: "node 8 tootip text", shape: "custom", size: 70, ctxRenderer: drawHexagon, color: "#3498DB", },
      { id: 3, label: "Su Ehtiyyatları", title: "node 8 tootip text", shape: "custom", size: 70, ctxRenderer: drawHexagon, color: "#85C1E9", },
      { id: 4, label: "Yer altı", title: "node 8 tootip text", shape: "custom", size: 70, ctxRenderer: drawHexagon, color: "#641E16", },
      { id: 5, label: "Yanğın", title: "node 8 tootip text", shape: "custom", size: 70, ctxRenderer: drawHexagon, color: "#E74C3C", },
    ],
    edges: [
      { from: 1, to: 2, arrows: { to: { enabled: false } } },
      { from: 1, to: 3, arrows: { to: { enabled: false } } },
      { from: 1, to: 4, arrows: { to: { enabled: false } } },
      { from: 1, to: 5, arrows: { to: { enabled: false } } },
    ]
  };

  const options = {
    layout: {
      // hierarchical: true,
      randomSeed: 2,
      // improvedLayout: true,
      // clusterThreshold: 130
    },
    edges: {
      color: "#000000",
      length: 250
    },
    nodes: {
      // shape: "custom",
      // image: require('assets/img/icons/common/hexagon.png').default,
      // size: 60,
      // color: "#8F85CE",
      // ctxRenderer: drawHexagon,
    },
    interaction: {
      hover: true,
      zoomView: false,
    },
    height: "100%"
  };

  const events = {
    select: (event) => {
      const { nodes, edges } = event;
      console.log(edges)
      if( nodes[0] === 1  ) {
        history.push(BASE_PATH)
        // console.log(node)
      } else if(nodes[0] >= 3) {
        history.push('/device')
      }
      console.log(nodes);
    },
  };

  return (
    <Fragment>
      <BaseNavbar />
      <Row className="align-items-center justify-content-center vh-100">
        <Graph
          graph={graph}
          options={options}
          events={events}
          getNetwork={network => {
            network.on("hoverNode", function (params) {
              network.canvas.body.container.style.cursor = 'pointer';
            })

            network.on("blurNode", function (params) {
              network.canvas.body.container.style.cursor = 'default';
            })
          }}
        />
      </Row>

      <Footer className="footer" />
    </Fragment>
  );
}



export default Security;
