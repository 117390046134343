
import React, {useState, useEffect} from "react";
import {
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  NavbarBrand,
  Media,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";

// import { strToUpper } from 'helpers/utils';
import { useUser } from "store/user-context";
import { LOGOUT } from "urls";
import { refreshFecth } from "store/refresh-request";
import { useAuth } from "store/auth-context";
import { shortName } from "helpers/utils";
import { useUserData } from "store/user-fetch";
import { BASE_PATH } from "helpers/constants";
import { useHistory } from "react-router-dom";

const useLogout = () => {
  const [logout, setLogout] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (logout) {
      const fetchForLogout = async () => {
        const response = await fetch(LOGOUT, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + auth.accessToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refresh_token: auth.refreshToken }),
        });
        if (!response.ok) {
          if(response.status !== 401 ) throw new Error(`Network response was not OK . Status = ${response.status}`);
          refreshFecth(auth);
          return
        }
        setLogout(false);
        auth.logout();
      }

      fetchForLogout();
    }
  }, [logout, auth]);

  return setLogout
}

const BaseNavbar = (props) => {

  const { user } = useUser()
  const { push } = useHistory()

  useUserData()

  const logout = useLogout()

  const {email, fullName, photo} = user;

  return (
    <>
      <Navbar className="navbar-nav navbar-light bg-white fixed-top custom-navbar-style" expand="md" id="navbar-main">
        <div className="custom-navbar container-fluid">
          <NavbarBrand
            className="pt-0 ml-2 mr-auto hover-pointer"
            onClick={(e) => push(BASE_PATH)}
          >
            <img
              alt='...'
              className="navbar-brand-img d-inline"
              src={
                require("assets/img/brand/ustunluk.png")
                            .default
              }
              width={"150px"}
            />
            {/* <h3 className="pl-3 d-inline">{strToUpper("Sumaks Üstünlük")}</h3> */}
          </NavbarBrand>


          <Nav className="align-items-center d-none d-md-flex ml-auto mr-0" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  {(photo) ? <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={photo}
                    />
                  </span> :
                    <span className="profile-rounded-circle">
                      {shortName(fullName ?? email)}
                    </span>
                  }
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {fullName ?? email}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {/* <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem> */}
                {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem> */}
                {/* <DropdownItem divider /> */}
                <DropdownItem href="#" onClick={(e) => {
                  e.preventDefault();
                  logout(true);
                }}>
                  <i className="ni ni-user-run" />
                  <span>Çıxış</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </div>
      </Navbar>
    </>
  );
};



export default BaseNavbar;
