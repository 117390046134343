import React, { Suspense, Fragment } from 'react';
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Admin from 'views';
import { useAuth } from 'store/auth-context';
import Login from 'views/Login'
import Village from 'views/path/village';
import Transport from 'views/path/transport';
// import House from 'views/path/house';
import Energy from 'views/path/energy';
import Water from 'views/path/water';
import Environment from 'views/path/environment';
import Security from 'views/path/security';
import {
  VILLAGE_PATH, ENERGY_PATH, SECURITY_PATH,
  BASE_PATH, ENVIRONMENT_PATH, TRANSPORT_PATH,
  WATER_PATH, LIGHT_PATH //, HOUSE_PATH
} from 'helpers/constants'
import Light from 'views/light'
import Device from 'views/water/index';


const App = () => {
  const {isLoggedIn} = useAuth()

  return (
    <Fragment>
      <Suspense
        fallback={<p>Loading...</p>}
      >
        {isLoggedIn ? <Switch>
          <Route path={VILLAGE_PATH} render={(props) => <Village {...props} />} />
          <Route path={TRANSPORT_PATH} render={(props) => <Transport {...props} />} />
          {/* <Route path={HOUSE_PATH} render={(props) => <House {...props} />} /> */}
          <Route path={ENERGY_PATH} render={(props) => <Energy {...props} />} />
          <Route path={WATER_PATH} render={(props) => <Water {...props} />} />
          <Route path={ENVIRONMENT_PATH} render={(props) => <Environment {...props} />} />
          <Route path={SECURITY_PATH} render={(props) => <Security {...props} />} />
          <Route path={LIGHT_PATH} render={(props) => <Light {...props} />} />
          <Route path='/device' render={(props) => <Device {...props} />} />
          <Route path={BASE_PATH} render={(props) => <Admin {...props} />} />
          <Redirect from="*" to={BASE_PATH} />
        </Switch> :
        <Switch>
        <Route path="/auth/login" render={(props) => <Login {...props} />} />
        <Redirect from="/" to="/auth/login" />
      </Switch>}
      </Suspense>
    </Fragment>
  );
}

export default withRouter(App);
