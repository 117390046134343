import React, { Fragment, useState } from "react";

import BaseNavbar from "components/Navbars/Exmaple";
import Footer from "components/Footers/DefaultFooter";
// import { useCompany } from "store/company-context";
import Graph from 'react-graph-vis'

import {
  Row,
  // Col,
  // Card,
  // CardBody,
} from 'reactstrap';

import {
  VILLAGE_PATH, ENERGY_PATH, SECURITY_PATH,
  ENVIRONMENT_PATH, TRANSPORT_PATH,
  WATER_PATH //, HOUSE_PATH
} from 'helpers/constants'

import {drawHexagon} from 'helpers/graph'
import { useHistory } from "react-router-dom";

const GRAPH = {
  nodes: [
    { id: 1, label: "ÜSTÜNLÜK", title: "node 1 tootip text", shape: "custom", size: 90, ctxRenderer: drawHexagon, color: "#DAF7A6", },
    { id: 2, label: "Kənd Təsərrüfatı", title: "node 2 tootip text", shape: "custom", size: 70, ctxRenderer: drawHexagon, color: "#FD8404", path: VILLAGE_PATH},
    { id: 3, label: "Nəqliyyat", title: "node 3 tootip text", shape: "custom", size: 70, ctxRenderer: drawHexagon, color: "#449EF3", path: TRANSPORT_PATH},
    { id: 4, label: "Enerji Sərfiyyatı", title: "node 5 tootip text", shape: "custom", size: 70, ctxRenderer: drawHexagon, color: "#B765D1", path: ENERGY_PATH }, // 1E6842
    { id: 5, label: "Su Sərfiyyatı", title: "node 10 tootip text", shape: "custom", size: 70, ctxRenderer: drawHexagon, color: "#1E6842", path: WATER_PATH }, // 009D07
    { id: 6, label: "Ətraf Mühit", title: "node 7 tootip text", shape: "custom", size: 70, ctxRenderer: drawHexagon, color: "#F0FB48", path: ENVIRONMENT_PATH },
    { id: 7, label: "Təhlükəsizlik", title: "node 8 tootip text", shape: "custom", size: 70, ctxRenderer: drawHexagon, color: "#1ADE23", path: SECURITY_PATH },
  ],
  edges: [
    { from: 1, to: 2, arrows: { to: { enabled: false } } },
    { from: 1, to: 3, arrows: { to: { enabled: false } } },
    { from: 1, to: 4, arrows: { to: { enabled: false } } },
    { from: 1, to: 5, arrows: { to: { enabled: false } } },
    { from: 1, to: 6, arrows: { to: { enabled: false } } },
    { from: 1, to: 7, arrows: { to: { enabled: false } } },
  ]
}

const options = {
  layout: {
    // hierarchical: false,
    randomSeed: 1,
  },
  edges: {
    color: "#000000",
    length: 250
  },
  nodes: {
    // shape: "custom",
    // image: require('assets/img/icons/common/hexagon.png').default,
    // size: 60,
    // color: "#8F85CE",
    // ctxRenderer: drawHexagon,
  },
  interaction: {
    hover: true,
    zoomView: false,
  },
  height: "100%"
};


const Admin = (props) => {

  const [graph, setGraph] = useState(GRAPH)
  const history = useHistory()

  const events = {
    select: (event) => {
      const { nodes } = event;
      // console.log(edges)
      if( nodes > 1 ) {
        const node = GRAPH.nodes.find(node => node.id === nodes[0] )
        history.push(node.path)
        // console.log(node)
      }
      console.log(nodes);
      if(false){
        setGraph(GRAPH)
      }
    },
  };

  return (
    <Fragment>
      <BaseNavbar />
      <Row className="align-items-center justify-content-center vh-100">
        <Graph
          graph={graph}
          options={options}
          events={events}
          getNetwork={network => {
            network.on("hoverNode", function (params) {
              network.canvas.body.container.style.cursor = 'pointer';
            })

            network.on("blurNode", function (params) {
              network.canvas.body.container.style.cursor = 'default';
            })
          }}
        />
      </Row>

      <Footer className="footer" />
    </Fragment>
  );
}



export default Admin;
