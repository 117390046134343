import { createSlice } from '@reduxjs/toolkit'

const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    devices: [],
  },
  reducers: {
    setState (state, action) {
      state.devices = action.payload
    },
    updateDevice(state, action) {
      const index = state.devices.findIndex(device => device.imei = action.payload.imei)
      if (index !== -1) {
        state.devices[index] = {
          ...state.devices[index],
          status: action.payload?.status,
          meter:action.payload?.meter,
        }
      }
    }
  }
})


const deviceReducer = deviceSlice.reducer
export const deviceActions = deviceSlice.actions

export default deviceReducer
