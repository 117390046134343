
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const Header = () => {

  const devices = useSelector(state => state.device.devices)



  const cards = devices.map(device => <Col lg="6" xl="4" key={device.imei}>
    <Card className="card-stats mb-4 mb-xl-0">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0"
            >
              {device.name}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">
              {device.type === 1 ? device.meter : device.type === 2 ? device.status ? "Qaz sızması var" : "Qaz sızması yoxdur" : device.status ? "Yer boşdur" : "Yer doludur"}
            </span>
          </div>
          <Col className="col-auto">
            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
              <i className="fas fa-chart-bar" />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
  )
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              {cards}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
