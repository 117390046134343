export const BASE_URL = "https://ustunluk.sumaks.az/api/v1/";
export const BASE = "https://ustunluk.sumaks.az/"

export const LOGIN = `${BASE_URL}auth/login/`;
export const REFRESH_TOKEN = `${BASE_URL}auth/refresh/`;
export const LOGOUT = `${BASE_URL}auth/logout/`;
export const USER_INFO = `${BASE_URL}user/`;

export const WEB_SOCKET = "https://ustunluk.sumaks.az"

export const LIGHT_DEVICE = `${BASE_URL}light-device/`
export const DEVICE_LIST = `${BASE_URL}device-list/`

export const getImageUrl = (path) => {
  if (path) return BASE + path
  return null
}
