import React from "react";
import { Card, Container, Row } from "reactstrap";
import Header from "components/Headers/Light";
// import Google from "components/Map/Google";
import MapBox from "components/Map/MapBox";
import BaseNavbar from "components/Navbars/Exmaple";
import { useLightDevice } from "hooks/light-device";
// import { useInitSocket } from "hooks/socket"


const Devices = () => {

  useLightDevice()
  // useLightDeviceUpdate()
  // useInitSocket()
  return (
    <>
      <BaseNavbar />
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              {/* <Google /> */}
              <MapBox />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Devices;
