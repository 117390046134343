/* eslint import/no-webpack-loader-syntax: off */
import React, { useState, useCallback } from "react";
// import { Link } from "react-router-dom";
import Map, { Marker, Popup, FullscreenControl, NavigationControl } from 'react-map-gl';
import { useDispatch, useSelector } from "react-redux";
// import { getImageUrl } from "urls";
import { Button } from "reactstrap";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { lightDeviceActions } from "store/slices/light-device-slice";
// import { useUpdater } from "hooks/socket";
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


const MapBox = () => {

  const devices = useSelector(state => state.lightDevice.devices);
  const dispatch = useDispatch()

  const [infos, setInfos] = useState([]);

  // useUpdater()

  const markerOnclick = useCallback((device, infos) => {
    const infosUpdate = [
      ...infos
    ];

    const currentIndex = infosUpdate.findIndex(current => current.imei === device.imei);
    if (currentIndex === -1) {

      const current = {
        imei: device.imei,
        name: device.name,
        lng: device.location.lng,
        lat: device.location.lat,
        light_1: device.light_1,
        light_2: device.light_2
      }
      infosUpdate.push(current);
    } else {
      infosUpdate.splice(currentIndex, 1);
    }
    // console.log(infosUpdate);
    setInfos(infosUpdate);
  }, []);



  const getMarkers = useCallback((devices, infos) => devices.map((device, i) =>
    device.location && <Marker
      longitude={device.location.lng}
      latitude={device.location.lat}
      onClick={e => markerOnclick(device, infos)}
      key={i}
    >
      <img
        src={
          device.light_1 || device.light_2 ? require("assets/img/icons/output-onlinepngtools.png").default : require("assets/img/icons/map-marker-icon.png").default
        }
        width={35}
        height={35}
        alt='...'
      />
    </Marker>
  ), [markerOnclick]);

  return (
    <Map
      initialViewState={{
        longitude: 49.844987,
        latitude: 40.344571,
        zoom: 13
      }}
      style={{ height: 600 }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY ?? 'pk.eyJ1Ijoic3VtYWtzIiwiYSI6ImNsM215ZHQ4ZDA5ZngzaXBiMzNmMzNveHEifQ.tMuyPJJvGj_w_v5rlrp07w'}
      locale='az'
    >
      <FullscreenControl />
      <NavigationControl />
      {getMarkers(devices, infos)}
      {infos.map((item, index) =>
        <Popup
          closeOnClick={false}
          longitude={item.lng}
          latitude={item.lat}
          key={index}
          closeButton={true}
          onClose={e => markerOnclick(item, infos)}
        >
          <div className="text-center">
            <h4>{item.name}</h4>
            {(item.light_1 || item.light_2) ?
              <Button
                color="danger"
                className="btn-sm"
                onClick={e => dispatch(
                  lightDeviceActions.changeDevice({imei: item.imei, response: 'OFF_ALL'})
                )}>Söndür</Button> :
              <Button color="success" className="btn-sm" onClick={e => dispatch(lightDeviceActions.changeDevice({imei: item.imei, response: 'ON'}))}>Yandır</Button>
            }
          </div>
        </Popup>
      )}
    </Map>
  );
}

export default MapBox;
