import { useEffect } from "react";
import { useAuth } from "store/auth-context";
import { DEVICE_LIST } from "urls";
import { useDispatch } from "react-redux";
import { deviceActions } from "store/slices/device-slice";

export const useDevice = () => {
  const auth = useAuth()
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchDevices = async () => {
      const response = await fetch(DEVICE_LIST, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + auth.accessToken,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        if(response.status !== 401 ) throw new Error(`Network response was not OK . Status = ${response.status}`);
      }
      const devices = await response.json()
      dispatch(deviceActions.setState(devices))
    }

    fetchDevices();
  }, [auth, dispatch]);
}
