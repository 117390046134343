import { useEffect } from "react";
import { useAuth } from "store/auth-context";
import { LIGHT_DEVICE } from "urls";
import { useDispatch, useSelector } from "react-redux";
import { lightDeviceActions } from "store/slices/light-device-slice";

export const useLightDevice = () => {
  const auth = useAuth()
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchDevices = async () => {
      const response = await fetch(LIGHT_DEVICE, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + auth.accessToken,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        if(response.status !== 401 ) throw new Error(`Network response was not OK . Status = ${response.status}`);
      }
      const devices = await response.json()
      dispatch(lightDeviceActions.setState(devices))
    }

    fetchDevices();
  }, [auth, dispatch]);
}

export const useLightDeviceUpdate = () => {
  const auth = useAuth()
  const devices = useSelector(state => state.lightDevice.devices)


  useEffect(() => {
    const device = devices.find(item => item.clicked)
    if (!device) return
    const fetchDevices = async () => {
      const response = await fetch(LIGHT_DEVICE + device.imei + '/', {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + auth.accessToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({response: device.response})
      });
      if (!response.ok) {
        if(response.status !== 401 ) throw new Error(`Network response was not OK . Status = ${response.status}`);
      }
    }

    fetchDevices();
  }, [devices, auth]);
}

