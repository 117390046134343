import React, { useCallback, useContext, useState } from 'react'


const UserContext = React.createContext({
  user: {
    email: '',
    fullName: '',
    id: null,
    photo: null,
    phone: '',
  },
  setState: (user) => {},
})


export const UserProvider = (props) => {
  const [user, setUser] = useState({
    email: '',
    fullName: '',
    id: null,
    photo: null,
    phone: '',
  });

  const setState = useCallback((user) => {
    setUser(user)
  }, [])

  const userContext = {
    user,
    setState,
  }

  return (
    <UserContext.Provider value={userContext}>
      {props.children}
    </UserContext.Provider>
  )
}


export const useUser = () => useContext(UserContext)

export default UserContext
