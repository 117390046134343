export const padZero = (str, len) => {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export const invertColor = (hex, bw = false) => {
  if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
  }
  let r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
      return (r * 0.299 + g * 0.587 + b * 0.114) > 186
          ? '#000000'
          : '#FFFFFF';
  }
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

export const drawHexagon = ({ ctx, x, y, state: { selected, hover }, style, label }) => {
  const r = style.size;
  ctx.beginPath();
  const sides = 6;
  const a = (Math.PI * 2) / sides;
  ctx.moveTo(x, y + r);
  for (let i = 1; i < sides; i++) {
    ctx.lineTo(x + r * Math.sin(a * i), y + r * Math.cos(a * i));
  }
  ctx.closePath();
  ctx.save();
  ctx.fillStyle = style?.color ?? 'red';
  ctx.fill();
  ctx.strokeStyle = invertColor(style?.color ?? 'red')
  ctx.lineWidth = r / 20
  ctx.shadowColor = '#000000';
  ctx.shadowBlur = 20;
  ctx.shadowOffsetX = 5;
  ctx.shadowOffsetY = 5;
  ctx.stroke();
  ctx.restore();

  const texts = label.split(' ')
  let max = texts[0]
  let maxFontSize = 24
  for (let i = 0; i < texts.length; i++) {
    if (texts[i].length > max.length) max = texts[i]
  }

  let fontSize = (r * Math.sqrt(3) - 10) / max.length * 2
  if (fontSize > maxFontSize) fontSize = maxFontSize;
  ctx.font = `normal ${fontSize}px sans-serif`;
  ctx.fillStyle = invertColor(style?.color ?? 'red', true);
  for (let i = 0; i < texts.length; i++) {
    ctx.fillText(texts[i], x - ctx.measureText(texts[i]).width / 2, y + fontSize * (i + 1 - texts.length / 2))
  }
  return ctx
}
