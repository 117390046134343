import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/odometer-theme-car.css";
import "assets/css/odometer-theme-default.css";
import "assets/css/odometer-theme-digital.css";
import "assets/css/odometer-theme-plaza.css";
import "assets/css/custom.css";
import 'react-notifications-component/dist/theme.css'

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
// import { Provider } from 'react-redux';
// import store from 'store/index';
// import { CookiesProvider } from 'react-cookie';
// import { AuthProvider } from "store/auth-context";
// import { FAuthProvider } from "store/f-auth-context";
import { AuthProvider } from "store/auth-context";
import { UserProvider } from "store/user-context";
import { Provider } from 'react-redux';
import store from "store/redux-store";
import { SocketProvider } from "store/socket-context";

import App from './App';


ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <UserProvider>
        <SocketProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SocketProvider>
      </UserProvider>
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);
