import { createSlice } from '@reduxjs/toolkit'

const lightDeviceSlice = createSlice({
  name: 'lightDevice',
  initialState: {
    devices: [],
  },
  reducers: {
    setState (state, action) {
      state.devices = action.payload.map((device) => {
        return {
          ...device,
          clicked: false,
        }
      })
    },
    changeDevice(state, action) {
      const index = state.devices.findIndex(item => item.imei === action.payload.imei)
      if(index !== -1){
        state.devices[index].clicked = true
        state.devices[index].response = action.payload.response
      }
    },
    updateDevice(state, action) {
      const index = state.devices.findIndex(device => device.imei = action.payload.imei)
      if (index !== -1) {
        state.devices[index] = {
          ...state.devices[index],
          clicked: false,
          light_1: action.payload.light_1,
          light_2: action.payload.light_2,
        }
      }
    }
  }
})

const lightDeviceReducer = lightDeviceSlice.reducer
export const lightDeviceActions = lightDeviceSlice.actions

export default lightDeviceReducer
