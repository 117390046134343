import { configureStore } from '@reduxjs/toolkit';

import lightDeviceReducer from './slices/light-device-slice';
import deviceReducer from './slices/device-slice';

const store = configureStore({
  reducer: {
    lightDevice: lightDeviceReducer,
    device: deviceReducer,
  }
});

export default store;
