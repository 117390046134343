import { useEffect } from "react"
import { useAuth } from "./auth-context"
import { useUser } from "./user-context"
import { USER_INFO } from "urls"
import { refreshFecth } from "./refresh-request"


export const useUserData = () => {
  const auth = useAuth()
  const { setState } = useUser()

  useEffect(() => {
    const fetchData = async () => {
      const headers = {
        'Authorization': 'Bearer ' + auth.accessToken,
        'Content-Type': 'application/json',
      }
      const response = await fetch(USER_INFO, {
        headers: headers,
      })

      if (!response.ok) {
        if (response.status === 401) {
          refreshFecth(auth);
          return;
        } else {
          throw new Error(`Network response was not OK . Status = ${response.status}`);
        }
      }
      const userData = await response.json();
      setState({
        email: userData.email,
        id: userData.id,
        fullName: userData.full_name,
        phone: userData.phone,
        photo: userData.photo,
      })
    }

    fetchData();
  }, [auth, setState])


  return
}
